import React, { useEffect, useState } from 'react';
import {
  RouteComponentProps,
  createMemorySource,
  createHistory,
  LocationProvider,
  Router,
} from '@reach/router';
import { ThemeProvider, createTheme, GlobalStyle, StoriesAttractor } from 'angel-ui';

// Page Components
import CollectionsPage from './pages/selection/collections';
import Stories from './pages/stories';
import DefaultCollection from './pages/selection/collections/Default';
import InteractiveCollection from './pages/selection/collections/Interactive';

import FilmAudioPage from './pages/collections/FilmAudio';
import LandscapeViewer from './pages/collections/LandscapeViewer';
import PortraitViewer from './pages/collections/PortraitViewer';

let source = createMemorySource('/');
let history = createHistory(source);
let navigate = history.navigate;

const CollectionsRoute = (props: RouteComponentProps) => <CollectionsPage {...props} />;
const StoriesRoute = (props: RouteComponentProps) => <Stories {...props} />;

const BooksRoute = (props: RouteComponentProps) => (
  <DefaultCollection {...props} type="books" title="Books" />
);
const FilmAudioCollectionRoute = (props: RouteComponentProps) => (
  <InteractiveCollection {...props} type="films" title="Film & Audio" />
);
const PhotoCollectionRoute = (props: RouteComponentProps) => (
  <InteractiveCollection {...props} type="photos" title="Photographs" />
);

const NewspaperCollectionRoute = (props: RouteComponentProps) => (
  <DefaultCollection {...props} type="newspapers" title="Newspapers" />
);
const PosterCollectionRoute = (props: RouteComponentProps) => (
  <DefaultCollection {...props} type="posterLeaflets" title="Posters & Leaflets" />
);
const MapsCollectionRoute = (props: RouteComponentProps) => (
  <InteractiveCollection {...props} title="Maps" type="maps" />
);
const FamilyCollectionRoute = (props: RouteComponentProps) => (
  <DefaultCollection {...props} title="Family History" type="familyHistory" />
);

const BookRoute = (props: RouteComponentProps) => (
  <PortraitViewer {...props} backPath="/books" type="books" />
);

const NewspaperRoute = (props: RouteComponentProps) => (
  <PortraitViewer {...props} backPath="/newspapers" type="newspapers" />
);

const PosterRoute = (props: RouteComponentProps) => (
  <PortraitViewer {...props} backPath="/posters" type="posterLeaflets" />
);

const FilmAudioRoute = (props: RouteComponentProps) => <FilmAudioPage {...props} type="films" />;

const PhotoRoute = (props: RouteComponentProps) => (
  <LandscapeViewer {...props} backPath="/photos" type="photos" />
);

const MapRoute = (props: RouteComponentProps) => (
  <LandscapeViewer {...props} backPath="/maps" type="maps" />
);

const FamilyHistoryRoute = (props: RouteComponentProps) => (
  <PortraitViewer {...props} backPath="/familyHistory" type="familyHistory" />
);

const App: React.FC = () => {
  
  return (
    <LocationProvider history={history}>
      <ThemeProvider theme={createTheme}>
        <GlobalStyle />

        <Router>
          <CollectionsRoute path="/" />
          <StoriesRoute path="/stories" />

          <BooksRoute path="/books" />
          <FilmAudioCollectionRoute path="/film-and-audio" />
          <PhotoCollectionRoute path="/photos" />
          <NewspaperCollectionRoute path="/newspapers" />
          <PosterCollectionRoute path="/posters" />
          <MapsCollectionRoute path="/maps" />
          <FamilyCollectionRoute path="/familyHistory" />

          <BookRoute path="/books/:id" />
          <NewspaperRoute path="/newspapers/:id" />
          <PosterRoute path="/posterLeaflets/:id" />
          <FilmAudioRoute path="/films/:id" />
          <PhotoRoute path="/photos/:id" />
          <MapRoute path="/maps/:id" />
          <FamilyHistoryRoute path="/familyHistory/:id" />
        </Router>
      </ThemeProvider>
    </LocationProvider>
  );
};

export default App;
