import React from 'react';
import styled from 'styled-components';
import { SecondaryButton } from 'angel-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface IButtonProps {
  fullWidth?: boolean;
}

interface ILabelProps {
  isPrev?: boolean;
}

const Label = styled.span<ILabelProps>`
  display: inline-block;
  ${props => (props.isPrev ? 'margin-left: 16px;' : 'margin-right: 16px;')}
`;

const StyledButton = styled(SecondaryButton)`
  color: #424242;
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  svg {
    color: ${props => props.theme.colors.gold};
  }
`;

const NextButton: React.FC<IButtonProps> = ({ children, fullWidth }) => {
  const props = {
    fullWidth,
  };

  return (
    <StyledButton {...props}>
      <Label>{children}</Label>
      <FontAwesomeIcon icon={faArrowRight} size="1x" />
    </StyledButton>
  );
};

const PrevButton: React.FC<IButtonProps> = ({ children, fullWidth }) => {
  const props = {
    fullWidth,
  };

  return (
    <StyledButton {...props}>
      <FontAwesomeIcon icon={faArrowLeft} size="1x" />
      <Label isPrev>{children}</Label>
    </StyledButton>
  );
};

export { NextButton, PrevButton };
