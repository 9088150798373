/**
 * Book Collection Page
 * This is the page that will show the book so users can
 * interact with the collection piece.
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, SecondaryButton, AssetInfoBox } from 'angel-ui';
import { RouteComponentProps, Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Zoom from '../../components/zoom';
import { PrevButton, NextButton } from '../../components/nav-button';

const previewHeight = 'calc(100vh - 300px)';

const ButtonWrap = styled(Flex)`
  background-color: #ffffff;
  padding: 24px 48px;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.grey};
  margin-top: 80px;
`;

const PageWrap = styled.div`
  background-color: #f6f6f6;
  position: relative;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: calc(100vh - 280px);
  object-fit: contain;
`;

const Wrap = styled.div`
  width: 620px;
  height: ${previewHeight};
  overflow: hidden;
  margin: 0 auto 40px;
  position: relative;
  border: 2px solid ${props => props.theme.colors.grey};
  background-color: ${props => props.theme.colors.white};
  top: 40px;

  div > div {
    bottom: 40px;
    left: 242px;
  }

  .iconButton {
    width: 48px;
    height: 50px;
    font-size: 1rem;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 0;
  }
`;

const PaginationButton = styled(SecondaryButton)`
  height: 50px;
  width: 48px;
  padding: 0;
  margin-right: 8px;

  svg {
    color: #394553;
  }
`;

const PaginationWrap = styled(Flex)`
  width: 120px;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  top: 40px;
`;

const BackButtonWrap = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 200;

  a {
    text-decoration: none;
  }
`;

const InfoBoxWrap = styled.div`
  position: absolute;
  top: 0;
  left: 12px;
  z-index: 100;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

interface NavParams {
  id: string;
}

interface IPortraitViewer extends RouteComponentProps<NavParams> {
  backPath: string;
  type: string;
  id?: string;
}

interface Element {
  id: string;
}

interface IData {
  id: number | null;
  transcriptText: string;
  title: string;
  informationText: string;
  images: {
    id: number;
    filename: string;
  }[];
}

const PortraitViewer: React.FC<IPortraitViewer> = ({ backPath, id, type }) => {
  const defaultData: IData = {
    id: null,
    images: [],
    title: '',
    informationText: '',
    transcriptText: '',
  };

  const [data, setData] = useState<IData>(defaultData);
  const [collection, setCollection] = useState<Array<IData>>([]);
  const [index, setIndex] = useState(0);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      if (loading === false) setLoading(true);

      const res = await fetch('https://archive.gateshead.gov.uk/api/collections');

      if (res.status === 200) {
        const d = await res.json();
        const refData = d.collections[type].find(
          (element: Element) => element.id.toString() === id
        );

        if (index !== 0) {
          setIndex(0);
        }

        setCollection(d.collections[type]);
        setData(refData);

        // The index of the current item needs to be set so we can find the
        // next item...
        setKey(d.collections[type].findIndex((element: Element) => element.id.toString() === id));
        setLoading(false);
      }
    }

    getData();
  }, [id, type]);

  return (
    <PageWrap>
      <BackButtonWrap>
        <Link to={backPath}>
          <PrevButton>Back</PrevButton>
        </Link>
      </BackButtonWrap>

      {!loading && (
        <>
          <InfoBoxWrap>
            <AssetInfoBox
              title={data.title}
              informationText={data.informationText}
              transcriptText={data.transcriptText}
            />
          </InfoBoxWrap>

          <Wrap>
            <Zoom>
              <Image src={data.images[index].filename} />
            </Zoom>
          </Wrap>

          <PaginationWrap>
            <PaginationButton onClick={() => setIndex(index - 1)} disabled={index === 0}>
              <FontAwesomeIcon icon={faChevronLeft} size="1x" />
            </PaginationButton>

            <PaginationButton
              onClick={() => setIndex(index + 1)}
              disabled={index + 1 === data.images.length}
            >
              <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </PaginationButton>
          </PaginationWrap>

          <ButtonWrap>
            <div>
              {key > 0 && (
                <StyledLink
                  to={`/${type}/${collection[key - 1].id}`}
                  onClick={() => setKey(key - 1)}
                >
                  <PrevButton>Previous</PrevButton>
                </StyledLink>
              )}
            </div>

            <div>
              {key + 1 !== collection.length && (
                <StyledLink
                  to={`/${type}/${collection[key + 1].id}`}
                  onClick={() => setKey(key + 1)}
                >
                  <NextButton>Next</NextButton>
                </StyledLink>
              )}
            </div>
          </ButtonWrap>
        </>
      )}
    </PageWrap>
  );
};

export default PortraitViewer;
