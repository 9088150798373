import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import slugify from 'slugify';
import { groupBy } from 'lodash';
import { Link } from '@reach/router';
import {
  GoogleMapProvider,
  GoogleMapConsumer,
  MapBox,
  CustomControl,
  InfoWindow,
} from '@googlemap-react/core';
import { MapInfoBox, StoryMapInfoBox } from 'angel-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Box, Flex, SecondaryButton } from 'angel-ui';
import { PrevButton } from '../nav-button';

import ButtonControl from './ButtonControl';
import MapMarker from './MapMarker';
import { GoogleMapObject } from '@googlemap-react/core/dist/src/common/types';

interface IPropsMap {
  backPath: string;
}

const ControlBox = styled(Box)`
  background-color: #f6f6f6;
  border-left: 1px solid ${props => props.theme.colors.grey};
  flex-direction: column;
  padding: 16px;
  ${props => props.rangeWrap && `width: 325px;`};
`;

const ControlWrapper = styled(Flex)`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey};
  display: flex;
  padding: 0 24px;
  position: relative;
  bottom: 30px;
  right: 30px;

  ${ControlBox} {
    &:last-of-type {
      border-right: 1px solid ${props => props.theme.colors.grey};
    }
  }
`;

const ZoomButton = styled(SecondaryButton)`
  height: 50px;
  width: 48px;
  padding: 0;
  margin-right: 8px;

  svg {
    color: #394553;
  }
`;

// Back Button
const BackLink = styled(Link)`
  font-family: 'itc-avant-garde-gothic-pro', sans-serif;
  position: relative;
  left: 10px;
  top: 10px;
  margin-bottom: 20px;
  text-decoration: none;
`;

interface IStory {
  id: number;
  longitude: number;
  latitude: number;
  imageFilename: string;
  category: string;
  storyText: string;
  title: string;
  imageIsPortrait: boolean;
}

const icons: any = {
  'landmarks-and-buildings': require('./images/filters/landmarks-and-buildings.svg'),
  people: require('./images/filters/local-people.svg'),
  events: require('./images/filters/major-events.svg'),
  'our-streets': require('./images/filters/our-streets.svg'),
  'grim-tales': require('./images/filters/grim-tales.svg'),
};

const StoriesMap: React.FC<IPropsMap> = ({ backPath }) => {
  const [allStories, setAllStories] = useState<Array<IStory>>([]);
  const [data, setData] = useState<Array<IStory>>([]);
  const [groupedData, setGroupedData] = useState<any>({});
  const [activeStory, setActiveStory] = useState<IStory | undefined>(undefined);
  const [currentCategory, setCurrentCategory] = useState<string | undefined>(undefined);
  const [activeFlag, setActiveFlag] = useState<boolean>(false);

  /**
   * Co-ordinates are hardcoded to central Gateshead
   */
  const center = {
    lat: 54.9420984,
    lng: -1.624152,
  };

  useEffect(() => {
    async function getStories() {
      const res = await fetch('https://archive.gateshead.gov.uk/api/stories.php');

      if (res.status === 200) {
        const data = await res.json();

        setAllStories(data.stories);
        setGroupedData(groupBy(data.stories, 'category'));
        setData(data.stories);
      } else {
        alert('There was a problem retrieving the Stories at this time.');
      }
    }

    getStories();
  }, []);

  const handleMarker = (id: number, map: GoogleMapObject) => {
    const story = allStories.find((story: IStory) => story.id === id);

    map.setCenter(map.getCenter());
    setActiveStory(story);
    setActiveFlag(true);
  };

  return (
    <GoogleMapProvider>
      <GoogleMapConsumer>
        {value => {
          return (
            <>
              <MapBox
                apiKey="AIzaSyCnLOvNuyEOfxjgrN8rkbVmJiviC52TzN0"
                opts={{
                  center,
                  zoom: 13,
                  fullscreenControl: false,
                  streetViewControl: false,
                  zoomControl: false,
                  tiltControl: false,
                  rotateControl: false,
                  mapTypeControl: false,
                  tilt: 0,
                  styles: [
                    {
                      featureType: 'poi',
                      stylers: [{ visibility: 'off' }],
                    },
                  ],
                }}
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />

              {data.length > 0 &&
                data.map(story => {
                  return (
                    <MapMarker
                      data={story}
                      key={story.id}
                      setMarkerState={(id: number) => handleMarker(id, value.state.map)}
                      closeMarker={() => {
                        setActiveFlag(false);
                        setActiveStory(undefined);
                      }}
                    />
                  );
                })}

              {activeStory && (
                <div>
                  <InfoWindow anchorId={`marker-${activeStory.id}`} visible={activeFlag}>
                    <MapInfoBox
                      imageSrc={activeStory.imageFilename}
                      category={activeStory.category}
                      informationText={activeStory.storyText}
                      title={activeStory.title}
                      closeHandler={() => setActiveFlag(false)}
                      isPortrait={activeStory.imageIsPortrait}
                    />
                  </InfoWindow>
                </div>
              )}

              <CustomControl bindingPosition="LEFT_TOP">
                <BackLink to={backPath}>
                  <PrevButton>Back</PrevButton>
                </BackLink>
              </CustomControl>

              <CustomControl bindingPosition="LEFT_TOP">
                <div style={{ position: 'relative', top: 30, left: 12 }}>
                  <StoryMapInfoBox
                    settings={{
                      defaultPosition: {
                        x: 0,
                        y: 0,
                      },
                    }}
                  >
                    <>
                      {Object.keys(groupedData).map((category, index) => {
                        const handleClick = () => {
                          setData(groupedData[category]);
                          setCurrentCategory(category);
                        };

                        const cat = slugify(category).toLowerCase();

                        return (
                          <ButtonControl
                            key={index}
                            onClick={() => handleClick()}
                            isActive={category === currentCategory}
                            icon={<img src={icons[cat]} alt="Filter Icon" />}
                          >
                            {category}
                          </ButtonControl>
                        );
                      })}

                      <ButtonControl
                        onClick={() => {
                          setCurrentCategory(undefined);
                          setData(allStories);
                        }}
                        icon={<img src={require('./images/filters/reset.svg')} alt="Reset Icon" />}
                      >
                        Reset Map to All Stories
                      </ButtonControl>
                    </>
                  </StoryMapInfoBox>
                </div>
              </CustomControl>

              <CustomControl bindingPosition="RIGHT_BOTTOM">
                <ControlWrapper>
                  <ControlBox>
                    <div>
                      <ZoomButton
                        onClick={() => {
                          const { state } = value;

                          state.map.setZoom(state.map.zoom + 1);
                          state.map.setCenter(state.map.getCenter());
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} size="1x" />
                      </ZoomButton>
                      <ZoomButton
                        onClick={() => {
                          const { state } = value;

                          state.map.setZoom(state.map.zoom - 1);
                          state.map.setCenter(state.map.getCenter());
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} size="1x" />
                      </ZoomButton>
                    </div>
                  </ControlBox>
                </ControlWrapper>
              </CustomControl>
            </>
          );
        }}
      </GoogleMapConsumer>
    </GoogleMapProvider>
  );
};

export default StoriesMap;
