import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import { Slider } from 'angel-ui';
import { Link, RouteComponentProps } from '@reach/router';
import { CollectionLayout, SpinnerWrap } from '../../../layouts';

const Title = styled.h2`
  color: #424242;
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
`;

const Copy = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 1.5;
`;

const Image = styled.img`
  object-fit: cover;
  height: 425px;
  width: 725px;
  margin-right: 24px;
`;

const Wrap = styled.div`
  display: flex;
  padding: 0 75px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

export interface IInteractiveCollectionProps extends RouteComponentProps {
  type: string;
  title: string;
}

//  Interface
interface Item {
  id: number;
  title: string;
  informationText: string;
  posterImageFilename?: string;
  thenImages: {
    id: number;
    filename: string;
  }[];
  images: {
    id: number;
    filename: string;
  }[];
}

class InteractiveCollection extends React.Component<IInteractiveCollectionProps> {
  state = {
    data: [],
    loading: true,
  };

  async componentDidMount() {
    const res = await fetch('https://archive.gateshead.gov.uk/api/collections');
    const { type } = this.props;

    if (res.status === 200) {
      const data = await res.json();

      this.setState({
        data: data.collections[type],
      });
    } else {
      alert('There was a problem retrieving this collection');
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '200px',
      slidesToShow: 1,
      speed: 500,
      arrows: false,
      swipe: true,
    };

    const { data, loading } = this.state;
    const { title, type } = this.props;

    return (
      <CollectionLayout title={title} backPath="/collections">
        {!loading ? (
          <Slider extraSettings={settings} noPadding showNav>
            {data.map((item: Item) => {
              return (
                <div key={item.id}>
                  <StyledLink to={`/${type}/${item.id}`}>
                    <Wrap>
                      <div>
                        <Image
                          src={
                            type === 'films'
                              ? item.posterImageFilename
                              : type === 'photos'
                              ? item.thenImages[0].filename
                              : item.images[0].filename
                          }
                        />
                      </div>

                      <div>
                        <Title>{item.title}</Title>
                        <Copy>{item.informationText}</Copy>
                      </div>
                    </Wrap>
                  </StyledLink>
                </div>
              );
            })}
          </Slider>
        ) : (
          <SpinnerWrap>
            <Spinner name="three-bounce" fadeIn="none" color="#b78b1d" />
          </SpinnerWrap>
        )}
      </CollectionLayout>
    );
  }
}

export default InteractiveCollection;
