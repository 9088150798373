/**
 * Story Map Button Control
 * This is the component used to filter pins by category.
 */

import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { SecondaryButton, Flex } from 'angel-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface IButtonControlProps {
  onClick: Function;
  isActive?: boolean;
  icon?: ReactElement;
}

const StyledFlex = styled(Flex)`
  align-items: center;
`;

const Button = styled(SecondaryButton)<IButtonControlProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  outline: none;
  padding: 10px 18px;

  ${props =>
    props.isActive &&
    `
    background-color: ${props.theme.colors.gold};
    color: ${props.theme.colors.white};
  `}

  span {
    font-size: 16px;
    font-family: 'itc-avant-garde-gothic-pro', sans-serif;
  }

  svg {
    color: ${props => (props.isActive ? props.theme.colors.white : props.theme.colors.gold)};
  }
`;

interface IIconWrapProps {
  isActive: boolean | undefined;
}

const IconWrap = styled.div<IIconWrapProps>`
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: 3px solid ${props => (props.isActive ? '#dec78f' : '#e8e8e8')};
  margin-right: 16px;

  img {
    transform: scale(0.7);
    ${props => props.isActive && `filter: brightness(0) invert(1)`};
  }
`;

const ButtonControl: React.FC<IButtonControlProps> = ({ children, onClick, isActive, icon }) => {
  return (
    <Button onClick={() => onClick()} isActive={isActive}>
      <StyledFlex>
        <IconWrap isActive={isActive}>{icon}</IconWrap>
        <span>{children}</span>
      </StyledFlex>
      <FontAwesomeIcon icon={faArrowRight} size="1x" />
    </Button>
  );
};

export default ButtonControl;
