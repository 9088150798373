import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';
import StoriesMap from '../../components/stories-map';

const MapWrap = styled.div`
  height: 100vh;
  position: relative;

  .gm-style-iw {
    padding: 0 !important;
    border-radius: 0;
    z-index: 9999;
    max-width: 800px !important;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
    max-width: 800px !important;
  }

  button.gm-ui-hover-effect {
    display: none !important;
  }
`;

const Stories: React.FC<RouteComponentProps> = () => {
  return (
    <div>
      <MapWrap>
        <StoriesMap backPath="/" />
      </MapWrap>
    </div>
  );
};

export default Stories;
