/**
 * Default Layout
 * This layout contains a header, footer and main container component.
 * Sometimes the header may not be required so we conditionally display
 * this with the use of a prop.
 */

import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Box, GALogo, Flex, Title } from 'angel-ui';
import { Header, Footer, Container } from './common';
import { PrevButton } from '../components/nav-button';
import { FooterLogo } from '.';

const StyledHeader = styled(Header)`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
`;

const HeaderTextWrap = styled(Flex)`
  justify-content: center;
  width: 100%;

  h1 {
    margin-bottom: 0;
  }
`;

const Wrap = styled.div`
  // display: flex;
  // flex-direction: column;
  // height: 100vh;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  height: 100vh;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledContainer = styled(Container)`
  padding: 35px 40px;
`

interface ILayoutProps {
  title: ITitleProps;
  backPath?: string;
}

interface ITitleProps {
  top: string;
  base: string;
}

const DefaultLayout: React.FC<ILayoutProps> = ({ children, title, backPath = '/' }) => {
  return (
    <Wrap>
      <StyledHeader>
        <Flex size={3} alignItems="center">
          <Box>
            <FooterLogo>
              <GALogo />
            </FooterLogo>
          </Box>

          <Box>
            <HeaderTextWrap>
              <Title
                topTitle={title.top}
                baseTitle={title.base}
                baseTitleSettings={{
                  fontWeight: 500,
                  size: 25,
                }}
                topTitleSettings={{
                  size: 50,
                  top: -28,
                  right: -2,
                }}
              />
            </HeaderTextWrap>
          </Box>
          <Box />
        </Flex>
      </StyledHeader>
      <main>
        <StyledContainer>{children}</StyledContainer>
      </main>
      <Footer>
        <Flex></Flex>
      </Footer>
    </Wrap>
  );
};

export default DefaultLayout;
