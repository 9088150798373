/**
 * Zoom Component
 * Increases the scale of an element
 */

import React from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import { Flex } from 'angel-ui';

const Zoom: React.FC = ({ children }) => {
  return (
    <Flex>
      <PinchZoomPan maxScale={5}>{children}</PinchZoomPan>
    </Flex>
  );
};

export default Zoom;
