/**
 * Default Layout
 * This layout contains a header, footer and main container component.
 * Sometimes the header may not be required so we conditionally display
 * this with the use of a prop.
 */

import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Box, GALogo, Flex } from 'angel-ui';
import { Header, Footer, Container } from './common';
import { PrevButton } from '../components/nav-button';
import { FooterLogo } from '.';

const HeaderTextWrap = styled(Flex)`
  justify-content: center;
  width: 100%;
`;

const StyledHeader = styled(Header)`
  padding: 40px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

interface IStyledTitleProps {
  isSmaller?: boolean;
}

const StyledTitle = styled.h1<IStyledTitleProps>`
  color: #424242;
  font-size: ${props => (props.isSmaller ? 72 : 90)}px;
  font-weight: 500;
  margin: 0;
`;

const StyledFlex = styled(Flex)`
  align-items: flex-start;
`;

interface ILayoutProps {
  title: string;
  backPath?: string;
}

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`;

const CollectionLayout: React.FC<ILayoutProps> = ({ children, title, backPath = '/' }) => {
  return (
    <Wrap>
      <StyledHeader>
        <StyledFlex size={3}>
          <Box>
            <StyledLink to={backPath}>
              <PrevButton>Back</PrevButton>
            </StyledLink>
          </Box>

          <Box>
            <HeaderTextWrap>
              <StyledTitle isSmaller={title === 'Posters & Leaflets'}>{title}</StyledTitle>
            </HeaderTextWrap>
          </Box>
          <Box />
        </StyledFlex>
      </StyledHeader>
      <main>
        <StyledContainer noPaddingBottom>{children}</StyledContainer>
      </main>
      <Footer>
        <Flex>
          <FooterLogo>
            <GALogo />
          </FooterLogo>
        </Flex>
      </Footer>
    </Wrap>
  );
};

export default CollectionLayout;
