import React, { useEffect } from 'react';
import slugify from 'slugify';
import { Marker } from '@googlemap-react/core';

interface IMapMarkerProps {
  setMarkerState: Function;
  closeMarker: Function;
  data: {
    id: number;
    latitude: number;
    longitude: number;
    category: string;
  };
}

const MapMarker: React.FC<IMapMarkerProps> = ({ data, setMarkerState, closeMarker }) => {
  const cat = slugify(data.category).toLowerCase();

  const icons: any = {
    'landmarks-and-buildings': {
      url: require('./images/landmarks-pin.svg'),
      scaledSize: {
        height: 60,
        width: 60,
      },
    },

    people: {
      url: require('./images/local-people-pin.svg'),
      scaledSize: {
        height: 60,
        width: 60,
      },
    },

    events: {
      url: require('./images/major-events-pin.svg'),
      scaledSize: {
        height: 60,
        width: 60,
      },
    },

    'our-streets': {
      url: require('./images/our-streets-pin.svg'),
      scaledSize: {
        height: 60,
        width: 60,
      },
    },

    'grim-tales': {
      url: require('./images/grim-tales-pin.svg'),
      scaledSize: {
        height: 60,
        width: 60,
      },
    },
  };

  // Clean up when un-mounted
  useEffect(() => {
    return () => {
      closeMarker();
    };
  }, []);

  return (
    <>
      <Marker
        id={`marker-${data.id}`}
        opts={{
          position: {
            lat: data.latitude,
            lng: data.longitude,
          },
          icon: icons[cat],
        }}
        onClick={() => setMarkerState(data.id)}
      />
    </>
  );
};

export default MapMarker;
