/**
 * Book Collection Page
 * This is the page that will show the book so users can
 * interact with the collection piece.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import { Flex, AssetInfoBox, SecondaryButton } from 'angel-ui';
import { RouteComponentProps, Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Zoom from '../../components/zoom';
import { PrevButton, NextButton } from '../../components/nav-button';
import { SpinnerWrap } from '../../layouts';

const previewHeight = 'calc(100vh - 104px)';

const ButtonWrap = styled(Flex)`
  background-color: #ffffff;
  padding: 24px 48px;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.grey};
`;

const PageWrap = styled.div`
  background-color: #f6f6f6;
  position: relative;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: ${previewHeight};
  object-fit: cover;
`;

const Wrap = styled.div`
  width: 100%;
  height: ${previewHeight};
  overflow: hidden;
  margin: 0 auto;
  position: relative;

  div > div {
    bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .iconButton {
    height: 50px;
    width: 48px;
    font-size: 1rem;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 0;
    margin: 0 8px 0 0 !important;
  }
`;

const BackButtonWrap = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 200;

  a {
    text-decoration: none;
  }
`;

const InfoBoxWrap = styled.div`
  position: absolute;
  top: 0;
  left: 12px;
  z-index: 100;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const PaginationButton = styled(SecondaryButton)`
  height: 50px;
  width: 48px;
  padding: 0;
  margin-right: 8px;

  svg {
    color: #394553;
  }
`;

const PaginationWrap = styled(Flex)`
  width: 120px;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  bottom: 130px;
`;

interface ILandscapeProps extends RouteComponentProps {
  backPath: string;
  type: string;
  id?: string;
}

interface IData {
  id: number | null;
  title: string;
  informationText: string;
  thenImages: {
    id: number;
    filename: string;
  }[];
  images: {
    id: number;
    filename: string;
  }[];
}

const LandscapeViewer: React.FC<ILandscapeProps> = ({ backPath, id, type }) => {
  const defaultData: IData = {
    id: null,
    images: [],
    thenImages: [],
    title: '',
    informationText: '',
  };

  const [data, setData] = useState<IData>(defaultData);
  const [loading, setLoading] = useState(true);
  const [collection, setCollection] = useState<Array<IData>>([]);
  const [key, setKey] = useState(0);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    async function getData() {
      const res = await fetch('https://archive.gateshead.gov.uk/api/collections');

      if (res.status === 200) {
        const d = await res.json();
        const refData = d.collections[type].find(
          (element: Element) => element.id.toString() === id
        );

        if (index !== 0) {
          setIndex(0);
        }

        setData(refData);
        setCollection(d.collections[type]);
        setKey(d.collections[type].findIndex((element: Element) => element.id.toString() === id));
        setLoading(false);
      }
    }

    getData();
  }, [id, type]);

  return (
    <PageWrap>
      <BackButtonWrap>
        <Link to={backPath}>
          <PrevButton>Back</PrevButton>
        </Link>
      </BackButtonWrap>

      {loading ? (
        <SpinnerWrap>
          <Spinner name="three-bounce" fadeIn="none" color="#b78b1d" />
        </SpinnerWrap>
      ) : (
        <>
          <InfoBoxWrap>
            <AssetInfoBox title={data.title} informationText={data.informationText} />
          </InfoBoxWrap>

          <Wrap>
            <Zoom>
              <Image
                src={type === 'photos' ? data.thenImages[index].filename : data.images[0].filename}
              />
            </Zoom>

            {type === 'photos' && (
              <PaginationWrap>
                <PaginationButton onClick={() => setIndex(index - 1)} disabled={index === 0}>
                  <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                </PaginationButton>

                <PaginationButton
                  onClick={() => setIndex(index + 1)}
                  disabled={index + 1 === data.thenImages.length}
                >
                  <FontAwesomeIcon icon={faChevronRight} size="1x" />
                </PaginationButton>
              </PaginationWrap>
            )}
          </Wrap>

          <ButtonWrap>
            <div>
              {key > 0 && (
                <StyledLink
                  to={`/${type}/${collection[key - 1].id}`}
                  onClick={() => setKey(key - 1)}
                >
                  <PrevButton>Previous</PrevButton>
                </StyledLink>
              )}
            </div>

            <div>
              {key + 1 !== collection.length && (
                <StyledLink
                  to={`/${type}/${collection[key + 1].id}`}
                  onClick={() => setKey(key + 1)}
                >
                  <NextButton>Next</NextButton>
                </StyledLink>
              )}
            </div>
          </ButtonWrap>
        </>
      )}
    </PageWrap>
  );
};

export default LandscapeViewer;
