/**
 * Video Collection Page
 * This is the page that will show the book so users can
 * interact with the collection piece.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import { Link } from '@reach/router';
import { AssetInfoBox, Flex, AVAudio, AVVideo } from 'angel-ui';
import { RouteComponentProps } from '@reach/router';
import { PrevButton, NextButton } from '../../components/nav-button';
import { SpinnerWrap } from '../../layouts';

const previewHeight = 'calc(100vh - 108px)';

const ButtonWrap = styled(Flex)`
  background-color: #ffffff;
  padding: 24px 48px;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.grey};
`;

const BackButtonWrap = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 200;

  a {
    text-decoration: none;
  }
`;

const PageWrap = styled.div`
  background-color: #f6f6f6;
  position: relative;
`;

const InfoBoxWrap = styled.div`
  position: absolute;
  top: 0;
  left: 12px;
  z-index: 150;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

interface IData {
  id: number | null;
  title: string;
  thumbnail_filename: string;
  thumbnail_cloud_url: string;
  filename: string;
  cloud_url: string;
  width: number | null;
  height: number | null;
  length: number | null;
  file_type: string;
}

interface IFilmAudioWrapProps extends RouteComponentProps {
  id?: string;
  type: string;
}

interface Element {
  id: number;
}

const FilmAudio: React.FC<IFilmAudioWrapProps> = ({ id, type }) => {
  const defaultData: IData = {
    id: null,
    title: '',
    thumbnail_filename: '',
    thumbnail_cloud_url: '',
    filename: '',
    cloud_url: '',
    width: null,
    height: null,
    length: null,
    file_type: '',
  };

  const [data, setData] = useState<IData>(defaultData);
  const [loading, setLoading] = useState(true);
  const [collection, setCollection] = useState<Array<IData>>([]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    async function getData() {
      const endpoint =
        process.env.NODE_ENV === 'production'
          ? 'https://archive.gateshead.gov.uk/api/av-tablet'
          : 'http://127.0.0.1:8000/api/av-tablet';
      const res = await fetch(endpoint);

      if (res.status === 200) {
        const d = await res.json();
        const refData = d.find((element: Element) => element.id.toString() === id);

        setCollection(d);
        setKey(d.findIndex((element: Element) => element.id.toString() === id));

        setData(refData);
        setLoading(false);
      }
    }

    getData();
  }, [id, type]);

  return (
    <PageWrap>
      <BackButtonWrap>
        <Link to="/">
          <PrevButton>Back</PrevButton>
        </Link>
      </BackButtonWrap>

      {loading ? (
        <SpinnerWrap>
          <Spinner name="three-bounce" fadeIn="none" color="#b78b1d" />
        </SpinnerWrap>
      ) : (
        <>
          {data.cloud_url.substr(data.cloud_url.lastIndexOf('.') + 1) === 'mp4' ? (
            <AVVideo src={data.cloud_url} height="100vh" />
          ) : (
            <AVAudio src={data.cloud_url} imgSrc={data.thumbnail_cloud_url} />
          )}

          {/* <ButtonWrap>
            <div>
              {key > 0 && (
                <StyledLink
                  to={`/${type}/${collection[key - 1].id}`}
                  onClick={() => setKey(key - 1)}
                >
                  <PrevButton>Previous</PrevButton>
                </StyledLink>
              )}
            </div>

            <div>
              {key + 1 !== collection.length && (
                <StyledLink
                  to={`/${type}/${collection[key + 1].id}`}
                  onClick={() => setKey(key + 1)}
                >
                  <NextButton>Next</NextButton>
                </StyledLink>
              )}
            </div>
          </ButtonWrap> */}
        </>
      )}
    </PageWrap>
  );
};

export default FilmAudio;
