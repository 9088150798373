/**
 * Area Selection Page
 * This page is used so that users can select the area of the map
 * they wish to view.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, RouteComponentProps } from '@reach/router';
import { Box, Grid } from 'angel-ui';
import { DefaultLayout, SpinnerWrap } from '../../../layouts';
import { NextButton } from '../../../components/nav-button';
import Spinner from 'react-spinkit';

const NextButtonWrap = styled.div`
  font-weight: 600;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 215px;

  button {
    background-color: #f6f6f6;
    font-weight: 500;
    padding: 14px;
    width: 100%;
    border: 0;
  }
`;

const StyledCard = styled.div`
  height: 230px;
  background-repeat: no-repeat;
  background-size: cover;
`;

interface IBoxProps {
  count?: number;
}

const StyledBox = styled(Box)<IBoxProps>`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${StyledCard} {
    position: relative;

    ${props =>
      props.count &&
      `
      height: ${525 / props.count - 8}px
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `}
  }
`;

const CollectionWrap = styled.div`
  h1 {
    margin-top: 40px;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 163px;
  object-fit: cover;
`;

const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 100;
  margin: 8px 0;
  padding: 0;
  line-height: 1.5em;
  width: 180px;
  color: #2c2526;
`;

interface MediaItem {
  id: number;
  title: string;
  thumbnail_filename: string;
  thumbnail_cloud_url: string;
  filename: string;
  cloud_url: string;
  width: number | null;
  height: number | null;
  length: number | null;
  file_type: string;
}

const CollectionsPage: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);
  // Get the images
  useEffect(() => {
    async function getImages() {
      const endpoint =
        process.env.NODE_ENV === 'production'
          ? 'https://archive.gateshead.gov.uk/api/av-tablet'
          : 'http://127.0.0.1:8000/api/av-tablet';
      const res = await fetch(endpoint);

      if (res.status === 200) {
        const data = await res.json();
        setLoading(false);
        setData(data);
      }
    }

    getImages();
  }, []);

  return (
    <CollectionWrap>
      <DefaultLayout title={{ top: 'Film & Audio ', base: 'Station' }}>
        {loading ? (
          <SpinnerWrap>
            <Spinner name="three-bounce" fadeIn="none" color="#b78b1d" />
          </SpinnerWrap>
        ) : (
          <Grid size={5} gap={16}>
            {data.map((row: MediaItem) => (
              <StyledBox key={row.id}>
                <Link to={`/films/${row.id}`} style={{textDecoration:'none'}}>
                  <StyledCard>
                    <Image src={row.thumbnail_cloud_url} />
                    <CardTitle>{row.title}</CardTitle>
                  </StyledCard>
                </Link>
              </StyledBox>
            ))}
          </Grid>
        )}
      </DefaultLayout>
    </CollectionWrap>
  );
};

export default CollectionsPage;
